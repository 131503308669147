import { styled } from '@mui/material/styles';
import {Box, Grid} from "@mui/material";
import MainCard from "../ui/cards/MainCard";

const Wrapper = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.primary.light,
	minHeight: '100vh'
}));

const AuthMainWrapper = ({ children, ...other }) => {
	return (
		<Wrapper>
			<Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
				<Grid item xs={12}>
					<Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
						<Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
							<MainCard
								sx={{
									maxWidth: { xs: 400, lg: 475 },
									margin: { xs: 2.5, md: 3 },
									'& > *': {
										flexGrow: 1,
										flexBasis: '50%'
									}
								}}
								content={false}
								{...other}
							>
								<Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>
									<Grid container spacing={2} alignItems="center" justifyContent="center">
										{children}
									</Grid>
								</Box>
							</MainCard>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Wrapper>
	)
}

export default AuthMainWrapper;