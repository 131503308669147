import MainCard from '../ui/cards/MainCard';
import UserRegistration from "./dashboardCharts/UserRegistration";
import {Grid} from "@mui/material";
import {useEffect, useState} from "react";
import AdminApi from "../../api/AdminApi";
import {useSelector} from "react-redux";
import UserAcception from "./dashboardCharts/UserAcception";
import UserStates from "./dashboardCharts/UserStates";
import UserCategories from "./dashboardCharts/UserCategories";

const initialData = {
	register_per_day: {},
	accepted_per_day: {},
	states: {},
	categories: {},
}

const Dashboard = () => {
	const [loaded, setLoaded] = useState(false);
	const { token } = useSelector(state => state.user)
	const [data, setData] = useState(initialData);

	useEffect(()=>{
		if(!loaded) {
			AdminApi.getStats(token).then(async (response) => {
				setData(response.data.data);
			}).catch((err) => {
				console.log("err", err);
			})
			setLoaded(true)
		}
	},[loaded, token])

	if(!loaded){
		return <>Loading</>
	}

	return (
		<MainCard title="Bine ai venit">
			<Grid container spacing={4}>
				<Grid item xs={12} md={6}>
					<UserRegistration data={data.register_per_day}/>
				</Grid>
				<Grid item xs={12} md={6}>
					<UserAcception data={data.accepted_per_day}/>
				</Grid>
				<Grid item xs={12} md={6}>
					<UserStates data={data.states}/>
				</Grid>
				<Grid item xs={12} md={6}>
					<UserCategories data={data.categories}/>
				</Grid>
			</Grid>
		</MainCard>
	)
}

export default Dashboard;
