import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Stack,
	Typography, useMediaQuery
} from '@mui/material';
import AnimateButton from "../ui/extended/AnimateButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import AuthMainWrapper from "./AuthMainWrapper";
import {activateSession, setToken, setUser} from "../../store/userSlice";
import AdminApi from "../../api/AdminApi";


export default function Login() {
	const theme = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

	const [loginEmail, setLoginEmail] = useState('');
	const [loginEmailError, setLoginEmailError] = useState('');
	const [loginPassword, setLoginPassword] = useState('');
	const [loginPasswordError, setLoginPasswordError] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [mainError, setMainError] = useState('');

	const handleLoginClick = async () => {
		if(checkLoginData()){
			setIsSubmitting(true);
			AdminApi.login({
				username:loginEmail,
				password:loginPassword
			}).then(async (response)=>{
				await localStorage.setItem("x-api-token-admin", response.data.accessToken);
				await dispatch(setUser(response.data.user));
				await dispatch(setToken(response.data.accessToken));
				await dispatch(activateSession());
				navigate('/dashboard');
			}).catch((err)=>{
				if (err.code && err.code === 401) {
					setMainError(err.errors[0]);
					setTimeout(()=>{setMainError("");},5000);
				}
			}).finally(()=>{
				setIsSubmitting(false);
			})
		}
	}

	const checkLoginData = () => {
		let errors = false;
		if(loginEmail.length===0){
			setLoginEmailError("Please complete your email");
			setTimeout(()=>{setLoginEmailError("");},5000);
			errors = true;
		}
		if(loginPassword.length===0){
			setLoginPasswordError("Please complete your password");
			setTimeout(()=>{setLoginPasswordError("");},5000);
			errors = true;
		}

		return !errors;
	}

	return (
		<AuthMainWrapper>
			<Grid item xs={12}>
				<Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems="center" justifyContent="center">
					<Grid item>
						<Stack alignItems="center" justifyContent="center" spacing={1}>
							<Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
								Admin
							</Typography>
							<Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
								Introduce credentialele pentru a continua
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<FormControl fullWidth error={Boolean(loginEmailError)} sx={{ ...theme.typography.customInput }}>
					<InputLabel htmlFor="email-login">Email</InputLabel>
					<OutlinedInput
						id="email-login"
						type="email"
						value={loginEmail}
						name="email"
						onChange={event=>setLoginEmail(event.target.value)}
						label="Email"
						inputProps={{}}
					/>
					{loginEmailError && (
						<FormHelperText error id="standard-weight-helper-text-email-login">
							{loginEmailError}
						</FormHelperText>
					)}
				</FormControl>

				<FormControl fullWidth error={Boolean(loginPasswordError)} sx={{ ...theme.typography.customInput }}>
					<InputLabel htmlFor="password-login">Parola</InputLabel>
					<OutlinedInput
						id="password-login"
						type={showPassword ? 'text' : 'password'}
						value={loginPassword}
						name="password"
						onChange={event=>setLoginPassword(event.target.value)}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={()=>setShowPassword(!showPassword)}
									edge="end"
									size="large"
								>
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						}
						label="Parola"
						inputProps={{}}
					/>
					{loginPasswordError && (
						<FormHelperText error id="standard-weight-helper-text-password-login">
							{loginPasswordError}
						</FormHelperText>
					)}
				</FormControl>

				<Box sx={{ mt: 2 }}>
					<AnimateButton>
						<Button
							disableElevation
							disabled={isSubmitting}
							fullWidth
							size="large"
							type="submit"
							variant="contained"
							color="secondary"
							onClick={handleLoginClick}
						>
							Login
						</Button>
					</AnimateButton>
					{mainError}
				</Box>
			</Grid>
		</AuthMainWrapper>
	)
}