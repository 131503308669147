import { Link } from 'react-router-dom';
import { ButtonBase } from '@mui/material';
import Logo from '../../ui/Logo';


const LogoSection = () => {
  return (
    <ButtonBase
        disableRipple
        component={Link}
        to="/dashboard"
        style={{textAlign:'center'}}
    >
      <Logo />
    </ButtonBase>
  );
};

export default LogoSection;
