import React, {useEffect, useState} from 'react';
import RoutedApp from "./RoutedApp";
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import themes from "./themes";
import {useDispatch, useSelector} from "react-redux";
import {activateSession, setToken, setUser} from "./store/userSlice";
import AdminApi from "./api/AdminApi";

function App() {
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const { sessionActive } = useSelector(state => state.user);

    useEffect(()=>{
        if(!loaded){
            if(!sessionActive && localStorage.getItem('x-api-token-admin')){
                AdminApi.getMe(localStorage.getItem('x-api-token-admin')).then(async (response)=>{
                    await dispatch(setUser(response.data.user));
                    await dispatch(setToken(localStorage.getItem('x-api-token-admin')));
                    await dispatch(activateSession());
                }).catch((err)=>{
                    console.log("err",err);
                }).finally(()=>{
                    setLoaded(true);
                })
            }else{
                setLoaded(true);
            }
        }
    },[loaded, sessionActive, dispatch])

    const configuration = {
        isOpen: [],
        defaultId: 'default',
        fontFamily: `'Roboto', sans-serif`,
        borderRadius: 12,
        opened: true
    }

    if(!loaded){
        return <></>
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(configuration)}>
                <CssBaseline />
                <RoutedApp />
            </ThemeProvider>
        </StyledEngineProvider>

    );
}

export default App;