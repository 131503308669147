import {Button, Tooltip} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import AdminApi from "../../api/AdminApi";
import {useSelector} from "react-redux";

const ExportButton = ({type}) => {
	const { token } = useSelector(state => state.user)

	const downloadExportFile = () => {
		if(type === "all"){
			AdminApi.exportAllUsers(token)
				.then(response=> {
					downloadCsvFile(
						process.env.REACT_APP_HOST_API + response.data.data.filePath,
						'export utilizatori.csv'
					);
				}).catch((err) => {
					console.log("err", err);
				})
		}else{
			AdminApi.exportAcceptedUsers(token)
				.then(response=> {
					downloadCsvFile(
						process.env.REACT_APP_HOST_API + response.data.data.filePath,
						'export utilizatori acceptati.csv'
					);
				}).catch((err) => {
				console.log("err", err);
				})
		}
	}

	const downloadCsvFile = (url, name) => {
		fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'text/csv',
			},
		})
			.then(response => response.blob())
			.then(blob => {
				const url = window.URL.createObjectURL(new Blob([blob]));

				const link = document.createElement('a');
				link.href = url;
				link.download = name;

				document.body.appendChild(link);

				link.click();

				link.parentNode.removeChild(link);
			});
	};



	return (
		<Tooltip title='Exporta utilizatorii din aceasta pagina in format csv' placement="left">
			<Button
				startIcon={<DownloadIcon stroke={1.5} size="1.3rem" />}
				variant="contained"
				color='info'
				onClick={downloadExportFile}
			>
				Exporta utilizatorii
			</Button>
		</Tooltip>
	);
};



export default ExportButton;
