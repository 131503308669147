import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import MainCard from '../ui/cards/MainCard';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import AdminApi from "../../api/AdminApi";
import {Link} from "react-router-dom";
import ExportButton from "../ui/ExportButton";

const Users = () => {
	const [loaded, setLoaded] = useState(false)
	const [data, setData] = useState([])
	const { token } = useSelector(state => state.user)

	useEffect(()=>{
		if(!loaded){
			AdminApi.getAllUsers(token).then(async (response)=>{
				const defaultData = response.data.data;
				setData(defaultData);
			}).catch((err)=>{
				console.log("err",err);
			}).finally(()=>{
				setLoaded(true);
			})
		}
	},[loaded, token])

	const renderState = (state) => {
		if(state === "starting"){
			return <span style={{
				background: "purple",
				color: 'white',
				whiteSpace: 'nowrap',
				padding: '10px',
				borderRadius: '5px',
			}}>
				Pre solicitare
			</span>
		}
		if(state === "rejected" || state === "reject"){
			return <span style={{
				background: "red",
				color: 'white',
				whiteSpace: 'nowrap',
				padding: '10px',
				borderRadius: '5px',
			}}>
				Refuz
			</span>
		}
		if(state === "pending"){
			return <span style={{
				background: "orange",
				color: 'white',
				whiteSpace: 'nowrap',
				padding: '10px',
				borderRadius: '5px',
			}}>
				Validare
			</span>
		}
		if(state === "accepted"){
			return <span style={{
				background: "green",
				color: 'white',
				whiteSpace: 'nowrap',
				padding: '10px',
				borderRadius: '5px',
			}}>
				Acceptat
			</span>
		}
		if(state === "decont"){
			return <span style={{
				background: "orange",
				color: 'white',
				whiteSpace: 'nowrap',
				padding: '10px',
				borderRadius: '5px',
			}}>
				Cerere decont
			</span>
		}
		if(state === "decont_accepted"){
			return <span style={{
				background: "green",
				color: 'white',
				whiteSpace: 'nowrap',
				padding: '10px',
				borderRadius: '5px',
			}}>
				Decont complet
			</span>
		}

		return state;
	}

	return (
		<MainCard
			title="Users"
			secondary={
				<ExportButton type="all" />
			}
		>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>ID</TableCell>
							<TableCell>Nume</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Activ</TableCell>
							<TableCell>Datele companiei completate</TableCell>
							<TableCell>Fisiere</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Actiuni</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row, index) => (
							<TableRow
								key={index}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell component="th" scope="row">
									{index+1}
								</TableCell>
								<TableCell component="th" scope="row">
									{row.name}
								</TableCell>
								<TableCell component="th" scope="row">
									{row.email}
								</TableCell>
								<TableCell component="th" scope="row">
									{row.active?"da":"nu"}
								</TableCell>
								<TableCell component="th" scope="row">
									{row.company.id?"da":"nu"}
								</TableCell>
								<TableCell component="th" scope="row">
									{row.files.length>0?"da":"nu"}
								</TableCell>
								<TableCell component="th" scope="row">
									{renderState(row.state)}
								</TableCell>
								<TableCell component="th" scope="row">
									<Button
										component={Link}
										to={`/user/${row.id}`}
										variant="contained"
									>
										Verifica
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</MainCard>
	)
};

export default Users;
