import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './counterSlice'
import userReducer from './userSlice'
import appReducer from './appSlice'

export const store = configureStore({
    reducer: {
        user: userReducer,
        counter: counterReducer,
        app: appReducer,
    },
})