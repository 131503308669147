import axios from "../util/axios";

export const endpoints = {
	auth: {
		me: '/api/admins/me',
		login: '/api/admins/login'
	},
	users: {
		list: '/api/admins/users',
		listAccepted: '/api/admins/usersAccepted',
		one: '/api/admins/user',
		updateUser: '/api/admins/user/update',
		updateUserFile: '/api/admins/user/updateFile',
		uploadUserFile: '/api/admins/user/uploadFile/',
	},
	getDefaultFiles: '/api/admins/files/default',
	getStats: '/api/admins/stats',
	exportUsers: {
		all: '/api/admins/export/all',
		accepted: '/api/admins/export/accepted',
	},
	resendUserVerificationEmail : '/api/admins/resendUserVerificationEmail',
};

const AdminsApi = {
	async login(data){
		return await axios.post(
			endpoints.auth.login,
			data
		)
	},
	async getMe(token){
		return await axios.post(
			endpoints.auth.me,
			{},
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async getAllUsers(token){
		return await axios.post(
			endpoints.users.list,
			{},
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async getAcceptedUsers(token){
		return await axios.post(
			endpoints.users.listAccepted,
			{},
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async getUser(id_user, token){
		return await axios.post(
			endpoints.users.one,
			{id_user},
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async getDefaultFiles(token){
		return await axios.post(
			endpoints.getDefaultFiles,
			{},
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async updateUserFile(data, token){
		return await axios.post(
			endpoints.users.updateUserFile,
			data,
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async updateUser(data, token){
		return await axios.post(
			endpoints.users.updateUser,
			data,
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async getStats(token){
		return await axios.post(
			endpoints.getStats,
			{},
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async exportAllUsers(token){
		return await axios.post(
			endpoints.exportUsers.all,
			{},
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async exportAcceptedUsers(token){
		return await axios.post(
			endpoints.exportUsers.accepted,
			{},
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async resendUserVerificationEmail(user_id, token){
		return await axios.post(
			endpoints.resendUserVerificationEmail,
			{id:user_id},
			{
				headers: {
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
	async uploadUserFiles(data, token, user_id){
		return await axios.post(
			endpoints.users.uploadUserFile+user_id,
			data,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					common: {
						Authorization : `Bearer ${token}`
					}
				}
			},
		)
	},
}

export default AdminsApi