import {Box, Button, Divider, FormHelperText, Grid, Modal, Paper, TextField, Typography} from '@mui/material';
import MainCard from '../ui/cards/MainCard';
import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import AdminApi from "../../api/AdminApi";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import Dropzone from "react-dropzone";
import FileUploadIcon from '@mui/icons-material/FileUpload';

const UserProfile = () => {
	const [loaded, setLoaded] = useState(false)
	const [data, setData] = useState([])
	const [errorData, setErrorData] = useState([])
	const [dataFile, setDataFile] = useState([])
	const [defaultFiles, setDefaultFiles] = useState([])
	const { token } = useSelector(state => state.user)
	let { id } = useParams();
	const navigate = useNavigate();
	const [openModel, setOpenModal] = useState(false);
	const [modalFile, setModalFile] = useState(null);
	const [reject, setReject] = useState('');
	const [rejectMainMessage, setRejectMainMessage] = useState('');
	const [rejectMainMessageError, setRejectMainMessageError] = useState('');


	useEffect (()=>{

		if(!loaded) {
			const fetchData = async() =>{

				await AdminApi.getUser(id, token).then(async (response) => {
					if (!response.data.data) {
						navigate('/dashboard')
					}
					setData(response.data.data);
				}).catch((err) => {
					console.log("err", err);
				})

				await AdminApi.getDefaultFiles(token).then(async (response) => {
					const defaultData = response.data.data;
					setDefaultFiles(defaultData);
				}).catch((err) => {
					console.log("err", err);
				})
				setLoaded(true);
			}

			fetchData();

		}
	},[loaded, token, id, navigate])

	if(!loaded){
		return <></>
	}

	const onOpenModal = (userFile) => {
		setModalFile(userFile);
		setOpenModal(true);
	}

	const handleCloseModal = () => {
		setOpenModal(false);
		setModalFile(null);
		setReject('');
	}

	const downloadFile = (url) => {
		const e = document.createElement('a');
		e.href = url;
		e.target = "_blank";
		e.download = url.substr(url.lastIndexOf('/') + 1);
		document.body.appendChild(e);
		e.click();
		document.body.removeChild(e);
	}

	const handleFileReject = () => {
		handleCloseModal();
		AdminApi
			.updateUserFile(
				{
					id: modalFile.id,
					action: 'rejected',
					message: reject,
				},
				token
			)
			.then(async (response)=>{
				const defaultData = response.data.data;
				setData(defaultData);
			})
	}

	const handleFileAccept = async() => {
		handleCloseModal();
		AdminApi
			.updateUserFile(
			{
					id: modalFile.id,
					action: 'accepted'
				},
				token
			)
			.then(async (response)=>{
			const defaultData = response.data.data;
			setData(defaultData);
		})
	}

	const handleUserReject = () => {

		setRejectMainMessageError('')
		if(rejectMainMessage.length<3){
			setRejectMainMessageError('Motivul retragerii este incomplet')
			return ;
		}
		AdminApi
			.updateUser(
				{
					id: data.id,
					action: 'rejected',
					message: rejectMainMessage,
				},
				token
			)
			.then(async (response)=>{
				const defaultData = response.data.data;
				setData(defaultData);
				setRejectMainMessage('');
			})
	}

	const handleSaveUserFile = () => {
		if(dataFile.length === 0){
			setErrorData({99:'Fisierul nu a fost introdus'})
		}

		AdminApi
			.uploadUserFiles(
				dataFile,
				token,
				data.id
			)
			.then(async (response)=>{
				const defaultData = response.data.data;
				setData(defaultData);
			})
	}

	const handleUserAccept = async() => {

		AdminApi
			.updateUser(
				{
					id: data.id,
					action: 'accepted'
				},
				token
			)
			.then(async (response)=>{
				const defaultData = response.data.data;
				setData(defaultData);
			})
	}

	const renderAccept = () => {
		if(
			data.state === 'starting' ||
			data.state === 'accepted' ||
			data.state === 'decont' ||
			data.state === 'decont_accepted'
		){
			return ;
		}
		return <Button
			onClick={handleUserAccept}
			variant='contained'
			style={{
				background:"green",
			}}
		>
			Accepta participarea la TIMM
		</Button>
	}

	const handleRejectMainMessage = (event) => {
		setRejectMainMessage(event.target.value);
		setRejectMainMessageError('');
	}

	const renderReject = () => {
		return ;
		if(data.state === 'starting' || data.state === 'rejected'){
			return ;
		}

		return (
			<div>
				<TextField
					label="Motivul refuzarii (va ajunge la client pe email)"
					fullWidth={true}
					value={rejectMainMessage}
					onChange={handleRejectMainMessage}
					error={rejectMainMessageError.length>0}
					helperText={rejectMainMessageError}
					multiline={true}
					style={{
						marginTop:'38px',
						marginBottom:'24px',
					}}
				/>
				<Button
					onClick={handleUserReject}
					variant='contained'
					style={{
						background:"red",
					}}
				>
					Refuza participarea la TIMM
				</Button>
			</div>
		)
	}

	const renderDecont = () => {
		if(data.state !== 'decont' ){
			return ;
		}

		let fileUploaded = null;

		if(dataFile[99]){
			fileUploaded = dataFile[99];
		}

		return (
			<div>
				<Dropzone
					multiple={false}
					accept={{
						'application/pdf': ['.pdf']
					}}
					onDrop={acceptedFiles => setFile(acceptedFiles[0], 99)}
				>
					{({getRootProps, getInputProps}) => (
						<section
							{...getRootProps()}
							style={{
								border: '1px solid #e3e3e3',
								textAlign: 'center',
								marginTop: '16px',
								padding: '16px',
								cursor: 'pointer',
							}}
						>
							<div >
								<FileUploadIcon />
								<input {...getInputProps()} />
								<p style={{
									margin:0,
									padding:0
								}}>
									Incarca dovada platii
								</p>
							</div>
						</section>
					)}
				</Dropzone>
				{fileUploaded &&
					<Typography variant="h5">
						{fileUploaded.name}
					</Typography>
				}

				{errorData[99] &&
					<FormHelperText className="errorTextField">{errorData[99]}</FormHelperText>
				}
				<br />
				<br />
				<Button
					onClick={handleSaveUserFile}
					variant='contained'
					style={{
						background:"green",
					}}
				>
					Salveaza fisierul
				</Button>
			</div>
		)
	}

	const handleResendEmail = async() => {
		AdminApi
			.resendUserVerificationEmail(
				data.id,
				token
			)
			.then(async (response)=>{
				if(response.data.success){
					alert("Email-ul a plecat spre utilizator");
				}
			})
	}

	const setFile = (file, position) => {
		setDataFile((prevState)=>{
			return {
				...prevState,
				[position]: file,
			}
		})

		setErrorData((prevState)=>{
			return {
				...prevState,
				[position]:''
			}
		})
	}

	return (
		<MainCard title={`Profilul utilizatorului ${data.name}`}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<h3>Compania</h3>
					<span style={{fontWeight: 'bold'}}>Denumire completa solicitant:</span> {data.company.nume} <br />
					<span style={{fontWeight: 'bold'}}>Cui:</span> {data.company.cui} <br />
					<span style={{fontWeight: 'bold'}}>Nr de inreg ONRC:</span> {data.company.onrc} <br />
					<span style={{fontWeight: 'bold'}}>Caen:</span> {data.company.caen} <br />
					<span style={{fontWeight: 'bold'}}>Categoria:</span> {data.company.categorie} <br />
					<span style={{fontWeight: 'bold'}}>Stand propiu:</span> {data.company.stand} <br />
					<span style={{fontWeight: 'bold'}}>Judet:</span> {data.company.judet} <br />
					<span style={{fontWeight: 'bold'}}>Adresa:</span> {data.company.adresa} <br />
					<span style={{fontWeight: 'bold'}}>Pozitie reprezentant legal:</span> {data.company.legal_pozitie} <br />
					<span style={{fontWeight: 'bold'}}>Nume reprezentant legal:</span> {data.company.legal_nume} <br />
					<span style={{fontWeight: 'bold'}}>Telefon:</span> {data.company.telefon} <br />
					<span style={{fontWeight: 'bold'}}>Email:</span> {data.company.email} <br />

					<span style={{fontWeight: 'bold'}}>Nume de contact:</span> {data.company.contact_nume} <br />
					<span style={{fontWeight: 'bold'}}>Telefon de contact:</span> {data.company.contact_telefon} <br />
					<span style={{fontWeight: 'bold'}}>Email de contact:</span> {data.company.contact_email} <br /><br />
					<span style={{fontWeight: 'bold'}}>Produse/servicii:</span> {data.company.intentie} <br />
					<Button
						variant="contained"
						onClick={handleResendEmail}
					>
						Retrimite email-ul de verificare
					</Button>
				</Grid>
				<Grid item xs={12} sm={6} style={{textAlign:'center'}}>
					<h3>Actiuni</h3>
					{renderAccept()}
					{renderReject()}
					{renderDecont()}
				</Grid>
			</Grid>
			<Divider sx={{marginTop:3,marginBottom:3}}/>
			<Grid container spacing={2} >
				<Grid item xs={12} sx={{marginBottom:3}}>
					<Typography variant="h3">
						Fisiere incarcate
					</Typography>
				</Grid>
				{defaultFiles.map(df=>{
					const userFile = data.files.filter(f=>df.id===f.id_default_file)
					return (
						<Grid key={df.id} item xs={6} sm={4}>
							<Paper elevation={3} sx={{padding:2}}>
								{df.name}
								{userFile.length>0 &&
									<>
										{userFile[0].state==='accepted' &&
											<Box sx={{
												margin: '12px',
												fontWeight:'bold',
												color:"green"
											}}>
												Fisierul a fost acceptat
											</Box>
										}
										{userFile[0].state==='rejected' &&
											<Box sx={{
												margin: '12px',
												fontWeight:'bold',
												color:"red"
											}}>
												Fisierul a fost respins
											</Box>
										}
										<Grid container spacing={2} sx={{marginTop: '12px'}}>
											{userFile[0].state === 'pending' &&
												<Grid item xs={6}>
													<Button
														onClick={() => onOpenModal(userFile[0])}
														variant="contained"
														fullWidth={true}
													>
														Deschide fisierul
													</Button>
												</Grid>
											}
											<Grid item xs={6}>
												<Button
													onClick={() => downloadFile(process.env.REACT_APP_HOST_API + userFile[0].path)}
													variant="contained"
													fullWidth={true}
												>
													Descarca fisier
												</Button>
											</Grid>
										</Grid>
									</>
								}
							</Paper>
						</Grid>
					)
				})}

			</Grid>

			<Modal
				open={openModel}
				onClose={() => handleCloseModal()}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box key="iframe" sx={{
					width: "80%",
					height: "100%",
					marginLeft: "10%",
					background:'white',

				}}>
					{modalFile !== null &&
						<>
							<iframe
								src={process.env.REACT_APP_HOST_API + modalFile.path}
								title="text"
								style={{
									width:'100%',
									height:'90%',
								}}
							>
							</iframe>
							<div style={{
								display:'flex',
								justifyContent:'space-between',
								padding:16,
								height:'10%',
								alignItems:'center',
							}}>
								<Button
									variant="contained"
									onClick={handleFileAccept}
									style={{
										background:"green",
										height: '100%',
									}}
								>
									Aproba
								</Button>
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<div />
								<TextField
									label="Motiv respingere"
									style={{width:'40%'}}
									value={reject}
									onChange={(event)=>setReject(event.target.value)}
								/>
								<Button
									variant="contained"
									onClick={handleFileReject}
									style={{
										background:"red",
										height: '100%',
									}}
								>
									Respinge
								</Button>
							</div>
						</>
					}
				</Box>

			</Modal>
		</MainCard>
	)
};

export default UserProfile;
