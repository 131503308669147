import React from 'react';
import {useSelector} from "react-redux";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Login from "./component/auth/Login";
import Dashboard from "./component/dashboard";
import MainLayout from "./component/MainLayout";
import UserProfile from "./component/dashboard/UserProfile";
import SelectedUsers from "./component/dashboard/SelectedUsers";
import Users from "./component/dashboard/Users";

function RoutedApp() {
	const { sessionActive } = useSelector(state => state.user);

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={
						sessionActive===undefined ?
							(<div>loading</div>)
							:
							(sessionActive?
									<Navigate to="/dashboard" />
									:
									<Navigate to="/login" />
							)
					}
				/>
				<Route
					path="/login"
					element={
						sessionActive?
							<Navigate to="/dashboard" />
							:
							<Login/>
					}
				/>
				<Route
					path="/dashboard"
					element={
						<MainLayout>
							<Dashboard />
						</MainLayout>
					}
				/>
				<Route
					path="/users"
					element={
						<MainLayout>
							<Users />
						</MainLayout>
					}
				/>
				<Route
					path="/selectedUsers"
					element={
						<MainLayout>
							<SelectedUsers />
						</MainLayout>
					}
				/>
			    <Route
			        path="/user/:id"
			        element={
		                <MainLayout>
		                    <UserProfile/>
		                </MainLayout>
			        }
			    />
			</Routes>
		</BrowserRouter>
	);
}

export default RoutedApp;