import {Box, Typography} from "@mui/material";
import Chart from "react-apexcharts";
import {useEffect, useState} from "react";

export default function UserStates({data}){
	const [values, setValues] = useState([]);
	const [keys, setKeys] = useState([]);

	useEffect(()=>{
		setKeys(Object.keys(data));
		setValues(Object.values(data));
	}, [data])

	const state = {
		series: values,
		options: {
			chart: {
				width: 380,
				type: 'pie',
			},
			labels: keys,
		},
	};

	return (
		<Box>
			<Typography variant={"h2"}>Statusurile utilizatorilor</Typography>
			<Chart
				options={state.options}
				series={state.series}
				type="pie"
				width="100%"
				height={320}
			/>
		</Box>
	)
}