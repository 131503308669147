import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const menuItems = {
	items: [
		{
			id: 'pages',
			title: 'Dashboard',
			type: 'item',
			url: '/dashboard',
			icon: DashboardIcon,
		},
		{
			id: 'users',
			title: 'Lista utilizatori',
			type: 'item',
			url: '/users',
			icon: PeopleIcon,
		},
		{
			id: 'files',
			title: 'Utilizatori acceptati',
			type: 'item',
			url: '/selectedUsers',
			icon: CheckBoxIcon,
		},
	]
};

export default menuItems;
