import Chart from 'react-apexcharts'
import {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";


function UserRegistration({data}){
	const [values, setValues] = useState([]);
	const [keys, setKeys] = useState([]);

	useEffect(()=>{
		setKeys(Object.keys(data));
		setValues(Object.values(data));
	}, [data])

	const state = {
		options: {
			chart: {
				toolbar: {
					show: false,
				}
			},
			xaxis: {
				categories: keys
			},
		},
		series: [{
			name: 'utilizatori',
			data: values
		}]
	}


	return (
		<Box>
			<Typography variant={"h2"}>Ziua inregistrari</Typography>
			<Chart
				options={state.options}
				series={state.series}
				type="bar"
				width="100%"
				height={320}
			/>
		</Box>
	)

}

export default UserRegistration