import { useDispatch, useSelector } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import Breadcrumbs from '../ui/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from './menu-items';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useEffect} from "react";
import {toggleMenu} from "../../store/appSlice";

const drawerWidth = 260;


// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    'margin',
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }
  ),
  [theme.breakpoints.up('md')]: {
    marginLeft: open ? 0 : -(drawerWidth - 20),
    width: `calc(100% - ${drawerWidth}px)`
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px',
    marginRight: '10px'
  }
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({children}) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
      dispatch(toggleMenu())
  };
  const navigate = useNavigate();
  const { sessionActive } = useSelector(state => state.user);
  const { menuOpened } = useSelector(state => state.app);

  useEffect(()=>{
      if(!sessionActive){
          navigate('/login');
      }
  },[sessionActive, navigate])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: menuOpened ? theme.transitions.create('width') : 'none'
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      <Sidebar drawerOpen={!matchDownMd ? menuOpened : !menuOpened} drawerToggle={handleLeftDrawerToggle} />

      <Main theme={theme} open={menuOpened}>
        <Breadcrumbs separator={ChevronRightIcon} navigation={navigation} icon title rightAlign />
          {children}
      </Main>
    </Box>
  );
};

export default MainLayout;
