import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	menuOpened: true,
}

const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setSidebarMode(state, action) {
			state.sidebarMode = action.payload
		},
		toggleMenu(state) {
			state.menuOpened = !state.menuOpened
		},
	},
})

export const { setSidebarMode, toggleMenu } = appSlice.actions
export default appSlice.reducer