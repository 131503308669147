import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	data: {},
	token: "",
	sessionActive: false,
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.data = action.payload
		},
		setToken: (state, action) => {
			state.token = action.payload
		},
		activateSession: (state) => {
			state.sessionActive = true
		},
		deactivateSession: (state) => {
			state.sessionActive = false
		},
	},
})

// Action creators are generated for each case reducer function
export const { setUser, setToken, activateSession, deactivateSession } = userSlice.actions

export default userSlice.reducer